import React from 'react'

export default function NotFoundPage() {
  return (
    <div className="h-[100vh] text-center flex flex-col items-center justify-center">
      <div className="flex align-center">
        <h1 className="mr-8 pr-8 border-r border-black text-xl font-medium leading-8">
          404
        </h1>
        <h2 className="leading-8">This page could not be found</h2>
      </div>
    </div>
  )
}

export function Head() {
  return (
    <>
      <title>404: Page not found</title>
    </>
  )
}
